


























































































@import '../../utilities/config';
.m-login-form {
  &__forgotpass {
    font-size: 14px;
  }
  &__suptitle {
    color: gray('gray-400');
    font-weight: 500; //font-weight: 400;;
    margin: 0;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 16px;
  }
  &__logo-link {
    display: block;
    margin-bottom: 24px;
  }
  &__btn {
    margin-top: 24px;
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  .m-login-form__logo-link {
    display: none;
  }
}
